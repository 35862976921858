import React, { useState } from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import Form from "../components/form"
import { Link } from 'gatsby'
import EmailListForm from "../components/emailListForm"
import bannerVideo from '../videos/Co-x3 Spring Animation(1920x750).mp4'
import fullVideo from '../videos/springanimation-notext.mp4'
import bannerGIF from '../cox3banner.gif'
import styled from 'styled-components'
import { Box, Flex } from '../elements'
import { colors } from '../styles/colors'
import { Button } from '../elements'
import { FaPatreon } from "react-icons/fa"
import ProudSVG from '../images/proudIMG.svg'
import Toolbox from '../images/toolbox.png'
import Img from "gatsby-image"
import CommunitySVG from '../images/network-members.png'
import { graphql } from 'gatsby'

const PatronList = styled.p`
font-size: 0.65rem;
color: grey;
`

const ProudImage = styled.img`
position: absolute;
right: 0px;
bottom: 25%;
width: 35%;
`

const SupportersOuter = styled.div`
position: relative;
width: 65%;
overflow-x: scroll;
scrollbar-width: none; 
-ms-overflow-style: none;
margin: 0 auto;
::-webkit-scrollbar{ 
  width: 0px;
}
`
const SupportersCont = styled.div`
display: grid;
grid-template-columns: max-content max-content;
grid-gap: 7px 20px;
padding-bottom: 15px
`

const SupporterName = styled.div`
`
const SupporterStatus = styled.div`
`

const PButton = styled(Button)<{ color: string }>`
background: #E85B46;
margin-bottom: 30px;
transition: all 0.5s ease;
:hover {
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.05);
}
`

const TButton = styled(Button)<{ color: string }>`
background: #E85B46;
margin: 15px;
transition: all 0.5s ease;
:hover {
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.05);
  background: white;
  color: black;
}
`
const CButton = styled(Button)<{ color: string }>`
background: #191F28;
margin: 15px;
transition: all 0.5s ease;
:hover {
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.05);
  background: white;
  color: black;
}
`


const ZButton = styled(Button)<{ color: string }>`
background: #2592AE;
margin: 15px;
transition: all 0.5s ease;
:hover {
  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.05);
  background: white;
  color: black;
}
`

const Patreon = styled(FaPatreon)`
position: relative;
top: 3px;
`

const PostFeed = styled(AnimatedBox)` 
position: relative;
display: flex;
flex-wrap: wrap;
margin: 0 -20px;
`

const CardLink = styled(Link)`
    text-decoration: none;
    flex-grow: 1;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const CardLinkExt = styled.a`
    text-decoration: none;
    flex-grow: 1;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

const PostCardStyles = styled(Box)`
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  max-height: 400px;
  background: #fff center center;
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;
  :hover {
    box-shadow: rgba(39, 44, 49, 0.07) 8px 28px 50px, rgba(39, 44, 49, 0.04) 1px 6px 12px;
    transition: all 0.4s ease;
    transform: translate3D(0, -1px, 0) scale(1.02);
  }
`

const WallCardStyles = styled(Box)`
  top: 25px;
  position: relative;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
  border-radius: 5px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  transition: all 0.5s ease;
`

const PatronCardStyles = styled(Box)`
  position: relative;
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 20px 40px;
`

const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  font-size: 85%;
`;

const PatronCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  padding-top: 20px;
  padding-bottom: 20px;
`;

const PostCardTags = styled.span`
  display: block;
  margin-bottom: 4px;
  color: ${colors.midgrey};
  font-size: .8rem;
  line-height: 1.15em;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;


const ReadingTime = styled.span`
  flex-shrink: 0;
  color: ${colors.midgrey};
  font-size: .8rem;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
const PublishedDate = styled.span`
  display: flex;
  flex-shrink: 0;
  color: ${colors.midgrey};
  font-size: .8rem;
  line-height: 33px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const PostCardTitle = styled.h4`
  margin-top: 0px;
  margin-bottom: 0px;
  color: black;
`
const PostCardMeta = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;


const PostCardImage = styled.div`
  width: auto;
  height: 200px;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  position: relative;
`;

const Cox3CardImage = styled.div`
  width: auto;
  height: 20vh;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  position: relative;
`;

const PostCardImageLink = styled(Link)`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const Cox3Video = styled.video`
  filter: brightness(33%); 
  -webkit-filter: brightness(33%);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Cox3VideoClear = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const TextCont = styled.div`
position: absolute;
top: 50%;
left: 50%;
margin-right: -35%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
`


const TextCont2 = styled.div`
position: absolute;
top: 45%;
left: 50%;
margin-right: -35%;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
`

const Title = styled.h1`
max-width: 1400px;
  color: white;
  font-size: 5vh;
`
const SubTitle = styled.h2`
max-width: 1400px;
color: white;
font-size: 2vh;
font-weight: 400;
`


const Category = styled(AnimatedBox)`
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
`

const PostCardStyles2 = styled(AnimatedBox)`
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 100vh;
  text-align: center;  
`;
const PostCardImage2 = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
  align-items: center;
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
  align-items: flex-start;
  padding-top: 10vh;
  padding-left: 375px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 1400px) {
    padding-left: 320px;
  }
  @media (max-width: 1125px) {
    padding-left: 2vh;
    padding-right: 2vh;
  }
`

const ModalBox = styled.div`
  width: calc(100vw - 40px);
  max-width: 900px;
  box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  border-radius: 5px;
  background: #fff;
  padding: 4rem;
  text-align: center;
  position: relative;
  max-height: 80%;
  overflow-y: auto;
  p {
    margin-bottom: 30px;
  }
  @media (max-width: 700px) {
    padding: 30px;
  }
`

const ModalCloseOverlay = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 0;
`

const ModalImage = styled.img`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: auto;
`

const Top100 = styled.b`
font-size: 110%;
margin-bottom: 5px;
font-weight: 650;
`
const AnnualPatron = styled.b`
font-size: 105%;
margin-bottom: 5px;
font-weight: 600;
`

const About = ({ data }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  
  const categoryAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
  })

  const titleAnimation = useSpring({ config: config.slow, delay: 300, from: { opacity: 0 }, to: { opacity: 1 } })
  
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Layout color="white">
      <SEO
      title="Co-x3 Family | Conrad"
      desc="What is Co-x3? It stands for co-creation, collaboration, and community."
      banner={bannerGIF}
      />
      <AnimatedBox style={pageAnimation}>
        
      <PostCardStyles2 className="post-card">
      <PostCardImage2 className="post-card-image">
                  <Cox3Video autoPlay muted loop playsinline>
                    <source  src={fullVideo} type="video/mp4" />
                  </Cox3Video>
      </PostCardImage2>
      <TextCont2>
      <Category style={categoryAnimation}>It's Time To <mark>Level Up</mark>.</Category> 
      <Title style={titleAnimation}><mark>Co-x3 Family</mark> Foundation</Title>
      <SubTitle>The Co-x3 Family Foundation is a 501(c)(3) non-profit organization that helps students, creatives, and retirees unlock their potential, make positive impact, and live their best life.</SubTitle> 
      {/*<a href="https://x3.conradlin.com/?utm_source=conradlin.com" target="_blank"><ZButton color="white" py={4} px={7}>Join Us</ZButton></a>
      <TButton onClick={() => setModalOpen(true)} color="white" py={4} px={7}>Wall of ♥</TButton>
  <br/>*/}
      <a href="https://x3.family/?utm_source=conradlin.com" target="_blank"><ZButton color="white" py={4} px={7}>Learn More 🤗</ZButton></a>
      </TextCont2>
      </PostCardStyles2>

        {/*<h1>Welcome! 🤗</h1>
        <p>
        What is Co-x3? It stands for <b>co-creation, collaboration, and community</b>. It's an inclusive group of like-minded forward thinkers that want to effect positive change in the world. Recently, we've embarked on a journey to <Link to="/theGamificationProject/">gamify our lives</Link> and made a pledge to <Link to="/be-intentional/">live intentionally in 2020</Link>. 
        </p>
        <p><b>As for myself,</b> I'm a creator passionate about tackling global challenges and unleashing breakthroughs. I love to share and co-create with my community whenever I come up with cool new ways to solve everyday challenges. I consider it my life's meaning to venture into the chaotic and dark unknown to bring back meaningful and useful applications for us to enjoy.    
        </p>
        <p>If this sounds like your cup of tea 🍵 (or coffee ☕, no judgment), we'd love to have you as part of the community. We share unique and interesting content freely, but with your support, we can release quality content regularly, and provide <a href="https://www.patreon.com/conradlin?utm_content=perks&utm_source=conradlin.com" target="_blank">special and customized perks</a> for supporters who identify strongly with our mission.</p>
        <h2 style={{ textAlign: 'center'}}>How Can You Get Involved?</h2>
        <PostFeed>
        <PostCardStyles style={{ margin: 10 }}>
                <CardLinkExt href="https://x3.conradlin.com/?utm_content=co-x3&utm_source=conradlin.com" target="_blank">
                <PostCardImage className="post-card-image">
                  <Cox3VideoClear autoPlay muted loop playsinline>
                    <source  src={bannerVideo} type="video/mp4" />
                  </Cox3VideoClear>
                </PostCardImage>
                <PostCardContent>
                    <p style = {{ color: "black" }}>Join the <strong>Co-x3 Community 🤗</strong>, an inclusive space where we co-create and collaborate with like-minded thinkers about the topics that matter most to you.</p>
                </PostCardContent>
                </CardLinkExt>
        </PostCardStyles>
        <PostCardStyles style={{ margin: 10 }}>
                <CardLinkExt href="https://x3.conradlin.com/notion" target="_blank">
        <PostCardImage className="post-card-image">
                  <Img className="post-card-img"
                    alt={`toolbox`}
                    style={{ width: '100%', height: '100%' }}
                    
                    fluid={data.toolbox.childImageSharp.fluid}
                   />
                </PostCardImage>
                <PostCardContent>
                    <p style = {{ color: "black" }}>If you're interested in systems to being highly productive & efficient, check out our exclusive <strong>Co-x3 Community Toolbox 🧰</strong> for useful notion templates & more!</p>
                </PostCardContent>
                </CardLinkExt>
        </PostCardStyles>
        
      
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 6]} px={[6, 6, 8, 6, 8, 11]} >
      <h2 style={{ textAlign: 'center'}}>We're So Proud Of The Impact We've Made...</h2>
      
      <Img
        fluid={data.review.childImageSharp.fluid}
        style={{ width: '100%', height: 'auto' }}
        alt={`reviews`}
      />
      </AnimatedBox>
     */}
      </AnimatedBox>
      
      <ModalContainer className={modalOpen ? 'is-open' : ''}>
        <ModalCloseOverlay type="button" onClick={() => setModalOpen(false)} />
        <ModalBox>
        <ModalImage src={CommunitySVG} alt="Modal Image" />
        <PatronCardStyles style={{ textAlign: 'center'}}>
        <PatronCardContent>
          <h1>Our Wall of 💕</h1>
        <p>Co-x3 is a space of co-creation and collaboration built for the community. Your support means the 🌎 to us, and keeps this project going.<br/><br/><b>Thank you! ♥ - Conrad</b>
        </p>
        <h4>Proudly Supported By:</h4>
        <Top100>Nikki Metzler</Top100>
        <Top100>🚀🚀🚀</Top100>
        <PatronList><strong>Top 100 Patron</strong></PatronList>
        <AnnualPatron>Alex, Ann, Cass, Drew, Heidi, Julian, Karen, Mathias, Olaf, Yvonne.</AnnualPatron>
        <AnnualPatron>2️⃣0️⃣2️⃣0️⃣</AnnualPatron>
        <PatronList><strong>Annual Patrons</strong></PatronList>
        <SupportersOuter>
        <SupportersCont style={{ textAlign: 'left'}}>
        <SupporterName>
        Courtney
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Tabatha
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Nikolas Patel
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Alex
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Cruise Rodriguez
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Jason
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Jimmy John
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Marlom
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Nick
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Tamir Shklaz
        </SupporterName>
        <SupporterStatus>
        🐥🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Alberto J. Azevedo
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Alessandra Veliz
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Asger Teglgaard
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Christopher Russo
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Deversorivm
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Hite Rodriguez
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Roxanne Oda
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Travis Martin
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Winston Teng
        </SupporterName>
        <SupporterStatus>
        🐥🐥 ...
        </SupporterStatus>
        <SupporterName>
        Alex Pax
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Amy Utley
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Chris Fior
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Justin Phillips
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Kale Watchous
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Kylie Chim
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Maxime Caldwell
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Michael Schultz
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        <SupporterName>
        Ses
        </SupporterName>
        <SupporterStatus>
        🐥 ...
        </SupporterStatus>
        </SupportersCont>
        </SupportersOuter>
        <PatronList><strong>Co-x3 Patrons: # of 🐥 = # of months support</strong><br />Last Updated: 2020-Jul-6</PatronList>
        <PatronList>🐣<br/>Abe, Aimee, Alex, Alexis, Aly, Alysha, Andrew, Ann, Antoine, Artelligence, Amanda, Anshu, Ashantus, Austin, Brian, Borislav, BRADLEY, Cassandra, Cassie, Cesar, Charles, Chen, Christophe, Christopher, Colin, Craig, Damien, Daniel, Daniela, Darius, David, de Bengala, Debora, Don, Derrick, Drew, Duong, EDGAR!, Elena, Elizabeth, Ema, Fabian, Geng, Georgina, Ghita, Hanna, Hans, Harry, Heidi, Hemshikha, Henry, iasha, Iris, Isak, Ivonne, Javier, Jay, Jeevan, Jeremy, Jihoach, Jim, Jiyun, Joe, Jon, Josh, joshua, Jules, Julia, Julian, Kacper, Karen, Kate, Keenan, Kevin, Kiki, Kuku, Lauren, Lilian, Linda, Lori, Lukas, Luiz, Lydia, Madelon, Maggie, Malte, Marlom, Marte, Mathias, Matthijs, Michael, Michelle, Miguel, Mike, Min, Misha, Nigel, Nik, Noah, Norn, Oliver, Omair, Parker, Rahik, Robert, Ryan, Sabrina, Sam, Sandra, Sandrine, Sashe, Scott, Sebastian, Selim, Shania, Shaun, Shawn, Sierra, Steve, Steven, Thanh, Tiana, Trenton, Veronika, William, Zakia, Zhang ...</PatronList><br/>
        <a href="https://www.patreon.com/conradlin?utm_content=peers&utm_source=conradlin.com" target="_blank">
        <ProudImage src={ProudSVG} alt="ProudPatrons" />
        <PButton color="white" py={4} px={7}>Join 70+ Patrons</PButton></a>
        <a href="https://our.co-x3.com/plans/43870?utm_source=conradlin.com" target="_blank">
        <ProudImage src={ProudSVG} alt="ProudPatrons" />
        <CButton color="white" py={4} px={7}>Support Us Annually</CButton></a>
        </PatronCardContent>
        </PatronCardStyles>
        </ModalBox>
      </ModalContainer>
    </Layout>
  )
}


export default About

export const query = graphql`
  query {
    review: file(relativePath: { eq: "reviews.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      
    }
    toolbox: file(relativePath: { eq: "toolbox.png" }) {
      childImageSharp {
        fluid(quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    
  }
  }
`
